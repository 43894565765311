@import 'variables';

.dashboard {
  width: 100%;
  background-color: #ebebeb;

  .card {
    overflow: hidden;
    background-color: #fff;
    border-radius: 15px;
  }
}

.user-profile {
  margin-bottom: 1rem;
  flex: 0 0 100%;
  max-width: 100%;
  min-height: 1px;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
  width: 100%;

  .avatar {
    width: 75px;
    height: 75px;
  }
}



@font-face {
  font-family: 'coolveticaregular';
  src: url('/src/Assets/sass/fonts/coolvetica_rg-webfont.woff2') format('woff2'),
  url('/src/Assets/sass/fonts/coolvetica_rg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v46/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.title {
  font-family: 'coolveticaregular', "Arial Black", sans-serif;
}

fieldset {
  border-style: none;
  padding: 5px;
  margin-left: -5px;
  margin-right: -5px;
  background: rgba(18, 91, 152, 0.05);
  border-radius: 8px;
}

legend {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #8898aa;
  padding: 10px;
  + * {
    clear: both;
  }
}

label {
  width: 100%;
}

.form-container {
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 1rem;
}


  input, button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
    color: #fff;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    background-color: white;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  input:-webkit-autofill {
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
  }

  input {
    -webkit-animation: 1ms void-animation-out;
  }

  input::-webkit-input-placeholder {
    color: #9bacc8;
  }

  input::-webkit-input-placeholder {
    color: #9bacc8;
  }

  input::-webkit-input-placeholder {
    color: #9bacc8;
  }

  button {
    display: block;
    width: 100%;
    height: 37px;
    background-color: $dojo-blue-light;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
  }

  button:active {
    background-color: $dojo-blue;
  }


.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid $border-light;
  border-radius: 4px;
  background-color: white;

  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  @extend .shadow-1;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}


// Credit Card Saved
[data-form="billing"]{
  .material-icons {
    color: #7c98eb;
    font-size: 4rem;
    border: 6px solid #7c98eb;
    padding: 0rem;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

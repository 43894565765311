._loading_overlay {

  &_wrapper {
    &--active {
      width: 100%;
      position: absolute;
      left: 0;
      height: 100%;
      top: 0;
      overflow: hidden;
      opacity: 1;
    }
  }

  &_overlay {
    background: $dojo-blue;
    transition: none;

    &:after {
      display: block;
      content: '';
      background-image: url('../img/ninja_black.png');
      background-size: cover;
      height: 300px;
      width: 300px;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: rotate(0deg);
      -webkit-animation:linear infinite alternate;
      -webkit-animation-name: run;
      -webkit-animation-duration: 3s;
    }

    @-webkit-keyframes run {
      0%{ bottom : -100%; left: 50%; transform: rotate(100deg); }
      50% { bottom: -20px; left: 0; transform: rotate(0deg); }
      75% { bottom: -20px; left: 0; transform: rotate(0deg); }
      100%{ bottom: -100%; left: 50%; transform: rotate(180deg); }
    }

  }

}

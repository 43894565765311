html,body {
  height: 100%;
}

html {
  overflow: hidden;
}

body {
  overflow-y: scroll;
  background: radial-gradient(
    ellipse at center,
    rgba($dojo-blue-lighter, 0) 0%,
    rgba($dojo-blue, 1) 100%
  );
  @include media-breakpoint-up(md) {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#root {
  padding: 1rem;
  @extend .container;
  @include media-breakpoint-up(lg) {
    padding: 0;
    max-width: 800px;
  }
}

.content-container {
  z-index: 1;
  position: relative;
  @include media-breakpoint-up(md) {
    margin: 72px auto;
    max-width: 800px;
  }
}

.vh-100 {
  height: 100vh;
}

.btn-register {
  margin-top: .5rem;
  //width: 190px;
  //position: absolute;
  //top: 0;
  //right: 20px;
}

// Header Navigation
.navbar {
  //border-radius: 15px 15px 0 0;
  &-brand {
    font-size: 14px;
    @include media-breakpoint-up(lg) {
      font-size: 1rem;
    }
  }
}

// Dashboard Navigation
.card .nav-tabs {
  box-shadow: none;
  display: flex;

  li {
    width: 100%;
    border-bottom: 1px solid #ccc;

    &:last-of-type {
      border-bottom: 0;
    }

    .nav-link {
      flex-direction: row;
      padding-left: 22px;
      span {
        margin-left: 1rem;
      }
      &.active {
        color: $dojo-blue;
      }
      &::before {
        background-color: $dojo-blue;
        @include media-breakpoint-up(lg) {
          height: 100%;
          width: 14px;
        }
      }
    }
  }

  &-indicator {
    display: none !important;
  }

  //li {
  //  width: 100%;
  //  margin-bottom: 1rem;
  //}
  //

  //
  //.nav-link {
  //  align-items: center;
  //  flex-direction: row;
  //  span {
  //    margin-left: 1rem;
  //  }
  //
  //  &::before {
  //    background-color: $dojo-blue;
  //    @include media-breakpoint-up(lg) {
  //      height: 100%;
  //      width: 5px;
  //    }
  //  }
  //
  //  &:active, &:focus, &:hover {
  //    box-shadow: none;
  //    background-color: transparent;
  //    @include media-breakpoint-up(lg) {
  //      background-color: #fff;
  //    }
  //  }
  //}
}
